import React, { useEffect, useState } from 'react';
// import * as XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import {
  LinearProgress,
  CircularProgress,
  TextField,
  IconButton,
  Dialog,
  Typography,
  Tooltip,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert as MuiAlert,
  InputLabel,
  FormControl,
  Grid,
  Input,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Modal,
  Box,
} from '@mui/material';
import { Spinner, Button } from 'reactstrap';
import moment from 'moment';
import {
  Cancel,
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  ErrorOutline as ExclamationCircleIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  CopyAllTwoTone,
  OpenInNew,
  MarkEmailReadOutlined as ResendEmailIcon,
} from '@mui/icons-material';
import {
  format,
  isAfter,
  isToday,
  startOfWeek,
  endOfWeek,
  addDays,
  addWeeks,
  subDays,
  subWeeks,
  endOfDay,
  startOfDay,
} from 'date-fns';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import './Cars.scss';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
// import ReactExport from 'react-excel-exportz';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import { generateStatusData } from '../utils/offerHelper';
import JSONViewer from '../components/JSONViewer';
import FilterModal from '../components/Filter';

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogContent = styled('div')(({ theme }) => ({
  // Define root and padding styles
  root: {
    padding: theme.spacing(2),
  },
}));

const region = process.env.REACT_APP_DEFAULT_REGION;
const isUK = region === 'UK';
const dateFormat = process.env.REACT_APP_DATE_FORMAT || 'DD-MMM-yyyy';

function JourneyLogsCell({ row }) {
  const regionalStatus = row.renewalJourneyLogs;
  const statusData = generateStatusData(region, regionalStatus, row.clientType);

  const getStatusIcon = (statusValue, title) => {
    const status = statusValue ? statusValue.toLowerCase() : null;
    const iconColor =
      status === 'successed' ? 'green' : status === 'errored' ? 'red' : 'gray';

    return (
      <Tooltip title={title} arrow>
        <div>
          {status ? (
            status === 'successed' ? (
              <CheckCircleRoundedIcon style={{ fill: iconColor }} />
            ) : (
              <CancelRoundedIcon style={{ fill: iconColor }} />
            )
          ) : (
            <ExclamationCircleIcon style={{ fill: iconColor }} />
          )}
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      {statusData.map(({ status, title }, index) => (
        <React.Fragment key={title}>
          {getStatusIcon(status, title)}
          {index !== statusData.length - 1 && (
            <span
              style={{ fontWeight: 'normal', color: 'green', fontSize: '12px' }}
            >
              -
            </span>
          )}
        </React.Fragment>
      ))}
      <JSONViewer title="Journey Log" jsonData={regionalStatus} />
    </>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function SubscriptionErrors(props) {
  const { filter } = props;
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filterOfferId, setFilterOfferId] = useState('');
  const [filterContractNumber, setFilterContractNumber] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterClient, setFilterClient] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterVin, setFilterVin] = useState('');
  // const [filterOfferDate, setFilterOfferDate] = useState('');
  const [email, setEmail] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contractStartDate, setContractStartDate] = useState('');
  const [offerEndDate, setOfferEndDate] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [vin, setVin] = useState('');
  const [currentOdometer, setCurrentOdometer] = useState('');
  const [calculatedOdometer, setCalculatedOdometer] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [offerId, setOfferId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openResendConfirmation, setOpenResendConfirmation] = useState(false);

  const [resendConfirmationData, setResendConfirmationData] = useState(null);
  const [apiResponse, setApiResponse] = useState({
    toastOpen: false,
    message: '',
    success: false,
  });
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [loadingRows, setLoadingRows] = useState({});
  const [appliedFilter, setAppliedFilter] = useState({});
  // const [downloadData, setDownloadData] = useState([]);
  // const [isDownloading, setIsDownloading] = useState(false);
  // const [downloadMsg, setDownloadMsg] = useState(null);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [downloadChoice, setDownloadChoice] = useState(null);
  // const { ExcelFile } = ReactExport;
  // const { ExcelSheet } = ReactExport.ExcelFile;
  // const { ExcelColumn } = ReactExport.ExcelFile;
  const [tabValue, setTabValue] = useState(null);
  const [crntDate, setCrntDate] = useState(new Date());
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [filterDisplay, setFilterDisplay] = useState(
    filter ? 'Filter Applied' : ''
  );
  const [disableNext, setDisableNext] = useState(false);
  const [disableDate, setDisableDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('failedContractCreations'); // Default tab

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleErrorTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabs = [
    { key: 'failedContractCreations', label: 'Failed Contract Creations' },
    { key: 'pdfNotAvailable', label: 'PDF Not Available' },
    { key: 'vinRegErrors', label: 'VIN/Reg Errors' },
  ];

  const updateDateFilters = (tab, currentDate) => {
    let startDate;
    let endDate;

    switch (tab) {
      case 0: // Daily
        startDate = currentDate;
        endDate = currentDate;
        break;
      case 1: // Weekly
        startDate = startOfWeek(currentDate);
        endDate = endOfWeek(currentDate);
        break;
      default:
        break;
    }

    setDataSource([]);
    setPage(0);
    setDisableDate(endDate);
    setFilterAfterDate(startDate);
    setFilterBeforeDate(endDate);

    setDisableNext(isAfter(endDate, new Date()) || isToday(endDate));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    let newDate;
    switch (newValue) {
      case 0: // Daily
        newDate = new Date();
        break;
      case 1: // Weekly
        newDate = startOfWeek(new Date());
        break;
      default:
        newDate = new Date();
        break;
    }
    setCrntDate(newDate);
    updateDateFilters(newValue, newDate);
  };

  useEffect(() => {
    setDisableNext(isAfter(disableDate, new Date()) || isToday(disableDate));
  }, [disableDate]);

  useEffect(() => {
    if (tabValue !== null) {
      updateDateFilters(tabValue, crntDate);
    }
  }, [tabValue, crntDate]);

  const handleNext = () => {
    setCrntDate((prevDate) => {
      let newDate;
      if (tabValue === 0) {
        newDate = addDays(prevDate, 1);
      } else if (tabValue === 1) {
        newDate = addWeeks(prevDate, 1);
      }
      updateDateFilters(tabValue, newDate);
      return newDate;
    });
  };

  const handlePrev = () => {
    setCrntDate((prevDate) => {
      let newDate;
      if (tabValue === 0) {
        newDate = subDays(prevDate, 1);
      } else if (tabValue === 1) {
        newDate = subWeeks(prevDate, 1);
      }
      updateDateFilters(tabValue, newDate);
      return newDate;
    });
  };

  const handleEditClick = (row) => {
    setEmail(row.offerCustomer.email || '');
    setFirstName(row.offerCustomer.firstName || '');
    setLastName(row.offerCustomer.lastName || '');
    setPostalCode(row.offerCustomer.postalCode || '');
    setOfferId(row.offerId || '');
    setAddressLine3(row.offerCustomer.addressLine3 || '');
    setStateCode(row.offerCustomer.stateCode || '');
    setVin(row.offerVehicle.vin || '');
    setRegistrationNumber(row.offerVehicle.registrationNumber || '');
    setContractStartDate(row.contractStartDate || '');
    setOfferEndDate(row.offerEndDate || '');
    setCalculatedOdometer(row.offerVehicle.calculatedOdometer || '');
    setCurrentOdometer(row.offerVehicle.currentOdometer || '');
    setOpenEditModal(true);
  };

  const handleResendConfirmation = (rowData) => {
    // console.log('resendDAta', rowData);
    setResendConfirmationData(rowData);
    setConfirmationEmail(rowData.offerCustomer.email);
    setOpenResendConfirmation(true);
  };
  const handleResendEmail = async (rowData) => {
    // Call the API endpoint to resend the email
    // setResendingEmail(true);
    setLoadingRows((prevLoadingRows) => ({
      ...prevLoadingRows,
      [rowData._id]: true,
    }));
    const response = await subscriptionsApi.resendInvitaionOfferEmail(
      dispatch,
      rowData.offerId
    );
    const message =
      response && response.success
        ? 'Email Sent Successfully'
        : 'Emai Sent Failed';
    // Set loading state for the current row to false
    setLoadingRows((prevLoadingRows) => ({
      ...prevLoadingRows,
      [rowData._id]: false,
    }));
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
    // setResendingEmail(false);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setApiResponse({
      toastOpen: false,
      message: '',
      success: false,
    });
  };

  const handleResendConfirmationClose = () => {
    setOpenResendConfirmation(false);
  };

  const handleResendConfirmationConfirm = () => {
    // Call the API to resend email
    handleResendEmail(resendConfirmationData);

    // Close the confirmation dialog
    setOpenResendConfirmation(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const loadFailedContractCreationsOffers = async (params) => {
    const offersData = await subscriptionsApi.getFailedContractCreationsOffers(
      dispatch,
      params
    );
    if (offersData && offersData.data.data.length > 0) {
      updateDataSource(offersData.data.data);
      setRowCount(offersData.data.totalItems);
    } else {
      setRowCount(offersData.data.totalItems);
      setDataSource([]);
    }
  };

  const loadPdfNotAvailableOffers = async (params) => {
    const offersData = await subscriptionsApi.getMaxRetryContractFailureOffers(
      dispatch,
      params
    );
    if (offersData && offersData.data.data.length > 0) {
      updateDataSource(offersData.data.data);
      setRowCount(offersData.data.totalItems);
    } else {
      setRowCount(offersData.data.totalItems);
      setDataSource([]);
    }
  };

  const loadVinRegErrorOffers = async (params) => {
    const offersData = await subscriptionsApi.getInvalidVinErrorOffers(
      dispatch,
      params
    );
    if (offersData && offersData.data.data.length > 0) {
      updateDataSource(offersData.data.data);
      setRowCount(offersData.data.totalItems);
    } else {
      setRowCount(offersData.data.totalItems);
      setDataSource([]);
    }
  };

  const prepareFilters = (currentPage) => {
    const filterValue = {};
    let dateDisplay = '';
    const displays = [];

    if (filterOfferId) filterValue.offer_id = { eq: filterOfferId };
    if (filterContractNumber)
      filterValue.contract_number = { eq: filterContractNumber };
    if (filterStatus) filterValue.offer_status = { eq: filterStatus };
    if (filterClient) filterValue.client_type = { eq: filterClient };
    if (filterVin && !isUK) filterValue.vin = { eq: filterVin };
    if (filterVin && isUK) filterValue.registration_number = { eq: filterVin };
    if (filterEmail) filterValue.email = { like: `%${filterEmail}%` };

    if (filterAfterDate || filterBeforeDate) {
      const startDate = filterAfterDate
        ? format(new Date(filterAfterDate), 'yyyy-MM-dd')
        : '1970-01-01';
      const endDate = filterBeforeDate
        ? format(new Date(filterBeforeDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd');
      filterValue.created_at = { between: [startDate, endDate] };
      dateDisplay = `Dates: ${startDate} - ${endDate}`;
    }

    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    const params = {
      filter: JSON.stringify(filterValue),
      limit: pageSize,
      page: currentPage + 1,
    };

    setAppliedFilter(filterValue);
    if (displays.length) setFilterDisplay(displays.join(' '));
    else setFilterDisplay('Filter Applied');

    return params;
  };

  const [previousTab, setPreviousTab] = useState(selectedTab);

  useEffect(() => {
    if (selectedTab !== previousTab) {
      setPreviousTab(selectedTab);
    }
  }, [selectedTab, previousTab]);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      let currentPage = page;
      if (selectedTab !== previousTab) {
        currentPage = 0;
        setPage(0); // Reset the page state to 0
      }
      const params = prepareFilters(currentPage);

      switch (selectedTab) {
        case 'failedContractCreations':
          await loadFailedContractCreationsOffers(params);
          break;
        case 'pdfNotAvailable':
          await loadPdfNotAvailableOffers(params);
          break;
        case 'vinRegErrors':
          await loadVinRegErrorOffers(params);
          break;
        default:
          break;
      }

      setIsLoading(false);
    };
    load();
  }, [
    page,
    pageSize,
    selectedTab, // Add this dependency for tab changes
    filterOfferId,
    filterContractNumber,
    filterStatus,
    filterClient,
    filterEmail,
    filterVin,
    filterAfterDate,
    filterBeforeDate,
  ]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const goToOfferDetails = (detailOfferId) => {
    window.open(`/offers/${detailOfferId}`, '_blank');
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'details') {
      goToOfferDetails(cellParams.row.offerId);
    }
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleEditSave = async () => {
    const updatedData = {
      email,
      postalCode,
      customerFirstName: firstName,
      customerLastName: lastName,
      contractStartDate,
      offerEndDate,
      addressLine3,
      stateCode,
      vin,
      registrationNumber,
      currentOdometer,
      calculatedOdometer,
    };
    setIsEditing(true);
    // Make API call to update the data
    const response = await subscriptionsApi.updateOfferById(
      dispatch,
      offerId,
      updatedData
    );
    setIsEditing(false);
    const message =
      response && response.success ? 'Update Successfully' : 'Update Failed';
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
    // Close the edit modal
    window.location.reload();
    setOpenEditModal(false);
  };

  const handleDeleteOffer = async () => {
    setIsDeleting(true);
    const response = await subscriptionsApi.deleteOffer(dispatch, offerId);
    setIsDeleting(false);
    const message =
      response && response.success ? 'Deleted Succesfully' : 'Delete Failed';
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
    window.location.reload();
    setOpenEditModal(false);
  };

  const resetFilters = () => {
    setDataSource([]);
    setPage(0);
    setFilterOfferId('');
    setFilterContractNumber('');
    setFilterStatus('');
    setFilterClient('');
    setFilterVin('');
    setFilterEmail('');
    // setFilterOfferDate('');
  };

  const resetDateFilters = () => {
    setTabValue(null);
    setFilterDisplay('');
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
  };

  const handleFilterOfferId = (event) => {
    setDataSource([]);
    setPage(0);
    setFilterOfferId(event.target.value);
  };

  const handleFilterContractNumber = (event) => {
    setDataSource([]);
    setPage(0);
    setFilterContractNumber(event.target.value);
  };

  const handleFilterStatus = (event) => {
    setDataSource([]);
    setPage(0);
    setFilterStatus(event.target.value);
  };

  const handleFilterClient = (event) => {
    setDataSource([]);
    setPage(0);
    setFilterClient(event.target.value);
  };

  const handleFilterEmail = (event) => {
    setDataSource([]);
    setPage(0);
    setFilterEmail(event.target.value);
  };

  const handleFilterVin = (event) => {
    setDataSource([]);
    setPage(0);
    setFilterVin(event.target.value);
  };

  const handleFilterOfferDate = (start, end) => {
    setDataSource([]);
    setPage(0);
    setFilterAfterDate(startOfDay(new Date(start)));
    setFilterBeforeDate(endOfDay(new Date(end)));
  };
  /*
  const downloadXLSX = (data, fileName) => {
    // Convert JSON data to a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reports');

    // Write the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert binary string to an ArrayBuffer
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        // eslint-disable-next-line no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };

    // Create a Blob from the ArrayBuffer
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the Object URL
    URL.revokeObjectURL(url);
  };

  const fetchOffersData = async (tab, params) => {
    let offersData;
    let fileName;
    switch (tab) {
      case 'failedContractCreations':
        offersData = await loadFailedContractCreationsOffers(params);
        fileName = 'Failed_Contract_Creations';
        break;
      case 'pdfNotAvailable':
        offersData = await loadPdfNotAvailableOffers(params);
        fileName = 'PDF_Not_Available';
        break;
      case 'vinRegErrors':
        offersData = await loadVinRegErrorOffers(params);
        fileName = 'VIN_Reg_Errors';
        break;
      default:
        console.warn('Unknown tab selected');
        offersData = { data: { data: [] } }; // Return an empty structure if tab is unknown
    }
    return { offersData, fileName };
  };

  const handleDownload = async (choice) => {
    // setDownloadChoice(choice);
    setOpenDialog(false);
    setIsDownloading(true);
    setDownloadData([]);
    setDownloadMsg(`Downloading 1 of ${Math.round(rowCount / 100)} Pages`);

    let reports = [];
    let fileName = 'errorReports';
    const params = {
      filter: JSON.stringify(appliedFilter),
      limit: pageSize,
    };

    if (choice === 'current') {
      params.page = page + 1;
      const { offersData, fileName: returnedFileName } = await fetchOffersData(
        selectedTab,
        params
      );
      fileName = returnedFileName || fileName;

      if (offersData && offersData.data && offersData.data.data.length > 0) {
        reports = offersData.data.data;
      }
    } else if (choice === 'entire') {
      params.limit = 100;
      const totalPages = Math.ceil(rowCount / 100);
      const fetchPromises = [];
      const { fileName: returnedFileName } = await fetchOffersData(
        selectedTab,
        params
      );
      fileName = returnedFileName || fileName;

      for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
        const pageParams = { ...params, page: currentPage };
        fetchPromises.push(fetchOffersData(selectedTab, pageParams));
      }

      const offersDataArray = await Promise.all(fetchPromises);
      offersDataArray.forEach((offersData, index) => {
        if (offersData && offersData.data) {
          reports = reports.concat(offersData.data.data);
        }
        setDownloadMsg(`Downloaded ${index + 1} of ${totalPages} Pages`);
      });
    }
    const mappedReports = reports.map((report) => {
      const emailVal =
        report.offerCustomer && report.offerCustomer.email
          ? report.offerCustomer.email
          : '';
      const fn =
        report.offerCustomer && report.offerCustomer.firstName
          ? report.offerCustomer.firstName
          : '';
      const ln =
        report.offerCustomer && report.offerCustomer.lastName
          ? report.offerCustomer.lastName
          : '';
      const nameVal = `${fn} ${ln}`;
      const createdAtVal = report.createdAt
        ? moment(report.createdAt).format(dateFormat)
        : '';
      const offerStatusVal = report.offerStatus || '';
      const expiringContractNumberVal =
        report.offerExpiringProduct &&
        report.offerExpiringProduct.contractNumber
          ? report.offerExpiringProduct.contractNumber
          : '';
      const newContractNumberVal =
        report.activatedOffer && report.activatedOffer.contractNumber
          ? report.activatedOffer.contractNumber
          : '';
      const clientVal =
        report.offeredProduct && report.offeredProduct.dealerClientName
          ? report.offeredProduct.dealerClientName
          : '';

      const renewalJourneyLogs = report.renewalJourneyLogs || {};
      const journeyLogs = generateStatusData(region, renewalJourneyLogs);

      const journeyLogData = journeyLogs.reduce((acc, log) => {
        acc[log.title] = log.status;
        return acc;
      }, {});

      return {
        offerId: report.offerId,
        email: emailVal,
        name: nameVal,
        createdAt: createdAtVal,
        offerStatus: offerStatusVal,
        expiringContractNumber: expiringContractNumberVal,
        newContractNumber: newContractNumberVal,
        client: clientVal,
        ...journeyLogData,
      };
    });

    setDownloadData(mappedReports);
    downloadXLSX(mappedReports, fileName);
    setIsDownloading(false);
    setDownloadMsg(null);
  };
*/
  const permissions = useSelector((state) => state.permissions.permissions);

  const dataColumns = [];
  dataColumns.push({
    headerName: 'Offer Date',
    field: 'createdAt',
    renderCell: (c) => moment(c.value).format(dateFormat),
    width: 110,
  });
  dataColumns.push({
    headerName: 'Offer Id',
    field: 'offerId',
    renderCell: (params) => {
      return (
        <div>
          <JSONViewer title="Offer" jsonData={params.row} />
          {params.row.offerId}
        </div>
      );
    },
    width: 210,
  });
  dataColumns.push({
    headerName: 'Email',
    field: 'offerCustomer.email',
    renderCell: (c) => c.row.offerCustomer.email,
    width: 320,
    disableColumnMenu: true,
  });
  dataColumns.push({
    headerName: 'Name',
    field: 'offerCustomer.firstName',
    renderCell: (c) =>
      `${c.row.offerCustomer.firstName} ${c.row.offerCustomer.lastName}`,
    width: 180,
    disableColumnMenu: true,
  });
  dataColumns.push({
    headerName: 'Status',
    render: (e) => e.offerStatus,
    field: 'offerStatus',
    width: 110,
    disableColumnMenu: true,
  });
  dataColumns.push({
    headerName: 'Expiring Contract #',
    field: 'offerExpiringProduct.contractNumber',
    renderCell: (c) => `${c.row.offerExpiringProduct.contractNumber}`,
    width: 135,
    disableColumnMenu: true,
  });
  dataColumns.push({
    headerName: 'Offer URL',
    render: (e) => e.offerUrl,
    field: 'offerUrl',
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            Tooltip="Open"
            onClick={() => window.open(params.row.offerUrl, '_blank')}
          >
            <OpenInNew />
          </IconButton>
          <IconButton
            Tooltip="Click to Copy!"
            onClick={() => navigator.clipboard.writeText(params.row.offerUrl)}
          >
            <CopyAllTwoTone />
          </IconButton>
        </div>
      );
    },
    width: 90,
    disableColumnMenu: true,
  });
  dataColumns.push({
    headerName: 'New Contract #',
    field: 'activatedOffer.contractNumber',
    renderCell: (c) =>
      `${
        c.row.activatedOffer && c.row.activatedOffer.contractNumber !== null
          ? c.row.activatedOffer.contractNumber
          : ''
      }`,
    width: 135,
    disableColumnMenu: true,
  });
  dataColumns.push({
    headerName: 'Client',
    field: 'clientType',
    renderCell: (c) => c.row.clientType,
    width: 120,
    disableColumnMenu: true,
  });
  dataColumns.push({
    headerName: 'Journey Logs',
    renderCell: (e) => <JourneyLogsCell row={e.row} />,
    field: 'renewalJourneyLogs1',
    width: isUK ? 395 : 295,
    disableColumnMenu: true,
  });
  if (permissions.OFFERS_DATA.READ === true) {
    dataColumns.push({
      headerName: 'Edit',
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
      cellClassName: 'custom-link-cell',
      width: 60,
      disableColumnMenu: true,
    });
    dataColumns.push({
      headerName: 'Resend',
      renderCell: (params) => (
        <IconButton
          aria-label="resend-email"
          onClick={() =>
            !loadingRows[params.row._id] && handleResendConfirmation(params.row)
          }
        >
          {loadingRows[params.row._id] ? (
            <CircularProgress size={24} />
          ) : (
            <ResendEmailIcon />
          )}
        </IconButton>
      ),
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      field: 'resendEmail',
      width: 60,
      disableColumnMenu: true,
    });
  }
  dataColumns.push({
    headerName: 'Details',
    renderCell: () => (
      <IconButton>
        <OpenInNew />
      </IconButton>
    ),
    field: 'details',
    width: 70,
    disableColumnMenu: true,
  });

  return (
    <Grid container className="offers">
      <Grid item sm={12}>
        <div className="d-flex flex-column align-items-end mb-2">
          <div className="d-flex justify-content-between align-items-center w-100 mb-2 flex-wrap">
            <div className="d-flex align-items-center">
              <h2 className="mr-2" style={{ marginRight: '20px' }}>
                Subscription Errors
              </h2>
              {/* {permissions.OFFERS_DATA.WRITE && (
                <Button
                  disabled={isDownloading}
                  color="primary"
                  variant="outlined"
                  onClick={() => setOpenDialog(true)}
                >
                  {downloadMsg ? (
                    <>
                      <span style={{ fontSize: '12px' }}>{downloadMsg}</span>
                      <CircularProgress
                        style={{ height: '25px', width: '25px' }}
                      />
                    </>
                  ) : (
                    'Download details'
                  )}
                </Button>
              )} */}
              <Tabs value={selectedTab} onChange={handleErrorTabChange}>
                {tabs.map((tab) => (
                  <Tab key={tab.key} value={tab.key} label={tab.label} />
                ))}
              </Tabs>
              {/* <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle style={{ padding: '16px 24px' }}>
                  Download Options
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenDialog(false)}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: '0px 24px 24px 24px' }}>
                  <DialogContentText>
                    Choose to download offer data for the current page or all
                    pages.
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: '8px 24px 16px' }}>
                  <Button
                    onClick={() => handleDownload('current')}
                    color="primary"
                  >
                    Current Page
                  </Button>
                  <Button
                    onClick={() => handleDownload('entire')}
                    color="primary"
                  >
                    All Pages
                  </Button>
                </DialogActions>
              </Dialog>
              {downloadData && downloadData.length ? (
                <ExcelFile hideElement>
                  <ExcelSheet data={downloadData} name="offerData">
                    <ExcelColumn label="offerId" value="offerId" />
                    <ExcelColumn label="Offer Date" value="createdAt" />
                    <ExcelColumn label="Email" value="email" />
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="Status" value="offerStatus" />
                    <ExcelColumn label="Client" value="client" />
                    <ExcelColumn
                      label="expiring contract"
                      value="expiringContractNumber"
                    />
                    <ExcelColumn
                      label="new contract"
                      value="newContractNumber"
                    />
                    <ExcelColumn
                      label="Offer Processed"
                      value="Offer Processed"
                    />
                    <ExcelColumn label="Offer Email" value="Offer Email" />
                    <ExcelColumn
                      label="Get Offer Details"
                      value="Get Offer Details"
                    />
                    <ExcelColumn
                      label="Customer Validation Completed"
                      value="Customer Validation Completed"
                    />
                    <ExcelColumn
                      label="Show Product Selection"
                      value="Show Product Selection"
                    />
                    <ExcelColumn
                      label="Needs & Demands Shown"
                      value="Needs & Demands Shown"
                    />
                    <ExcelColumn
                      label="Customer Checkout"
                      value="Customer Checkout"
                    />
                    <ExcelColumn
                      label="Payment Webhook"
                      value="Payment Webhook"
                    />
                    <ExcelColumn
                      label="Create Contract"
                      value="Create Contract"
                    />
                    <ExcelColumn
                      label="GLOW Activation Callback"
                      value="GLOW Activation Callback"
                    />
                    <ExcelColumn
                      label="Payment Info Updation"
                      value="Payment Info Updation"
                    />
                    <ExcelColumn
                      label="Monthly Schedule Email"
                      value="Monthly Schedule Email"
                    />
                    <ExcelColumn label="Show Offer" value="Show Offer" />
                    <ExcelColumn label="Show Quote" value="Show Quote" />
                    <ExcelColumn
                      label="Contract Inquiry and Document PDF"
                      value="Contract Inquiry and Document PDF"
                    />
                    <ExcelColumn
                      label="Confirmation Email"
                      value="Confirmation Email"
                    />
                  </ExcelSheet>
                </ExcelFile>
              ) : (
                ''
              )} */}
            </div>
            <div className="d-flex align-items-center">
              <FormControl
                style={{ marginLeft: '10px', display: isUK ? '' : 'none' }}
              >
                <InputLabel htmlFor="select-client-label">Client</InputLabel>
                <Select
                  labelId="select-client-label"
                  id="select-client"
                  value={filterClient}
                  onChange={handleFilterClient}
                  style={{ width: '140px', overflowY: 'auto' }}
                >
                  <MenuItem key="RAC" value="RAC">
                    RAC
                  </MenuItem>
                  <MenuItem key="Cazoo" value="Cazoo">
                    Cazoo
                  </MenuItem>
                  <MenuItem key="House Account" value="House Account">
                    House Account
                  </MenuItem>
                  <MenuItem key="Henson Motor Group" value="Henson Motor Group">
                    Henson Motor Group
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ marginLeft: '10px', marginRight: '10px' }}>
                <InputLabel htmlFor="select-status-label">Status</InputLabel>
                <Select
                  labelId="select-status-label"
                  id="select-status"
                  value={filterStatus}
                  onChange={handleFilterStatus}
                  style={{ width: '140px', overflowY: 'auto' }}
                >
                  <MenuItem key="ACTIVE" value="ACTIVE">
                    Active
                  </MenuItem>
                  <MenuItem key="EXPIRED" value="EXPIRED">
                    Expired
                  </MenuItem>
                  <MenuItem key="REDEEMED" value="REDEEMED">
                    Redeemed
                  </MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Advanced Search
              </Button>
              <Modal open={open} onClose={handleClose}>
                <Box sx={{ ...style, width: 400, padding: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h2>Advanced Search</h2>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <FormControl
                    style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}
                  >
                    <InputLabel
                      id="input-email"
                      shrink
                      style={{ fontSize: '1.2rem', color: '#000' }}
                    >
                      Email
                    </InputLabel>
                    <Input
                      labelId="input-email"
                      id="input-email-field"
                      value={filterEmail}
                      fullWidth
                      onChange={handleFilterEmail}
                    />
                  </FormControl>
                  <FormControl
                    style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}
                  >
                    <InputLabel
                      id="input-contract-number"
                      shrink
                      style={{ fontSize: '1.2rem', color: '#000' }}
                    >
                      Contract Number
                    </InputLabel>
                    <Input
                      labelId="input-contract-number"
                      id="input-contract"
                      value={filterContractNumber}
                      fullWidth
                      onChange={handleFilterContractNumber}
                    />
                  </FormControl>
                  <FormControl
                    style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}
                  >
                    <InputLabel
                      id="input-offer-id"
                      shrink
                      style={{ fontSize: '1.2rem', color: '#000' }}
                    >
                      Offer Id
                    </InputLabel>
                    <Input
                      labelId="input-offer-id"
                      id="input-offer"
                      value={filterOfferId}
                      fullWidth
                      onChange={handleFilterOfferId}
                    />
                  </FormControl>
                  <FormControl
                    style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}
                  >
                    <InputLabel
                      id="input-vin"
                      shrink
                      style={{ fontSize: '1.2rem', color: '#000' }}
                    >
                      VIN/Reg
                    </InputLabel>
                    <Input
                      labelId="input-vin"
                      id="input-vin-field"
                      value={filterVin}
                      fullWidth
                      onChange={handleFilterVin}
                    />
                  </FormControl>
                </Box>
              </Modal>
              {(filterOfferId ||
                filterContractNumber ||
                filterStatus ||
                filterEmail ||
                filterVin ||
                filterClient) && (
                <IconButton onClick={resetFilters}>
                  <Cancel color="black" />
                </IconButton>
              )}

              <Tabs
                value={tabValue !== null ? tabValue : false}
                onChange={handleTabChange}
              >
                {region === 'US' && <Tab label="Daily" value={0} />}
                {region === 'UK' && <Tab label="Weekly" value={1} />}
              </Tabs>
              <FilterModal
                currentFilters={appliedFilter}
                onSave={() => {
                  handleFilterOfferDate(filterAfterDate, filterBeforeDate);
                }}
                itemName="Reports"
                resetFilters={resetDateFilters}
                filterDisplay={filterDisplay}
                tabValue={tabValue}
              >
                <div style={{ marginBottom: '16px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="After Date"
                      variant="inline"
                      className="mr-2"
                      fullWidth
                      id="after-date-filter"
                      value={filterAfterDate}
                      onChange={setFilterAfterDate}
                      autoOk
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Before Date"
                      variant="inline"
                      className="mr-2"
                      fullWidth
                      id="before-date-filter"
                      value={filterBeforeDate}
                      onChange={(selectedDate) => {
                        // Set default time to 11:59 pm if date is selected
                        if (selectedDate) {
                          const modifiedDate = endOfDay(selectedDate); // Adjust to date-fns syntax
                          setFilterBeforeDate(modifiedDate);
                        } else {
                          setFilterBeforeDate(selectedDate);
                        }
                      }}
                      autoOk
                    />
                  </LocalizationProvider>
                </div>
              </FilterModal>
              <div className="d-flex align-items-center">
                {tabValue !== null && (
                  <>
                    <Button
                      onClick={handlePrev}
                      variant="contained"
                      color="primary"
                      style={{ marginRight: '10px' }}
                    >
                      Prev
                    </Button>
                    {filterDisplay.replace('Dates: ', '')}
                    <Button
                      onClick={handleNext}
                      variant="contained"
                      color="primary"
                      disabled={disableNext}
                      style={{ marginLeft: '10px' }}
                    >
                      Next
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        )}
        {!isLoading && dataSource && dataSource.length > 0 && (
          <div className="d-block d-md-flex flex-wrap">
            <div
              style={{
                height: '70vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DataGrid
                density="compact"
                paginationModel={{ page, pageSize }}
                pagination
                pageSizeOptions={PAGINATION_OPTIONS}
                rowCount={rowCount}
                getRowId={(row) => row._id}
                rows={dataSource}
                columns={dataColumns}
                onPaginationModelChange={handlePageChange}
                onCellClick={handleCellClick}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        )}
        <Dialog
          onClose={handleEditModalClose}
          aria-labelledby="customized-dialog-title"
          open={openEditModal}
          maxWidth="md" // Set the desired maximum width
          fullWidth
        >
          <CustomDialogTitle>
            <Typography variant="h6">Edit Offer Detail</Typography>
            <IconButton
              aria-label="close"
              className={CustomDialogTitle.closeButton}
              onClick={handleEditModalClose}
            >
              <CloseIcon />
            </IconButton>
          </CustomDialogTitle>
          <DialogContent
            style={{
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <div style={{ marginBottom: '10px' }}>
              <TextField
                id="edit-offer-end-date"
                label="Offer End Date"
                value={offerEndDate}
                onChange={(e) => setOfferEndDate(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <TextField
                id="edit-contract-start-date"
                label="Contract Start Date"
                value={contractStartDate}
                onChange={(e) => setContractStartDate(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <TextField
                id="edit-email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <TextField
                id="edit-firstName"
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <TextField
                id="edit-lastLame"
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px', display: isUK ? 'none' : '' }}>
              <TextField
                id="edit-address-line-3"
                label="Address Line 3"
                value={addressLine3}
                onChange={(e) => setAddressLine3(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px', display: isUK ? 'none' : '' }}>
              <TextField
                id="edit-state-code"
                label="State Code"
                value={stateCode}
                onChange={(e) => setStateCode(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <TextField
                id="edit-postal-code"
                label="Postal Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px', display: isUK ? 'none' : '' }}>
              <TextField
                id="edit-vin"
                label="VIN"
                value={vin}
                onChange={(e) => setVin(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px', display: isUK ? '' : 'none' }}>
              <TextField
                id="edit-registration-number"
                label="Registration Number"
                value={registrationNumber}
                onChange={(e) => setRegistrationNumber(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px', display: isUK ? 'none' : '' }}>
              <TextField
                id="edit-current-odometer"
                label="Current Odometer"
                value={currentOdometer}
                onChange={(e) => setCurrentOdometer(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginBottom: '10px', display: isUK ? 'none' : '' }}>
              <TextField
                id="edit-calculated-odometer"
                label="Calculated Odometer"
                value={calculatedOdometer}
                onChange={(e) => setCalculatedOdometer(e.target.value)}
                fullWidth
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditSave}
              style={{ margin: '5px 10px 15px 0' }}
              disabled={isEditing}
            >
              {isEditing ? (
                <>
                  <span>Please Wait...</span>
                  <Spinner
                    color="dark"
                    style={{ height: '25px', width: '25px' }}
                  />
                </>
              ) : (
                'Save Changes'
              )}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteOffer}
              style={{
                margin: '5px 10px 15px 10px',
                display: permissions.SQL_EDITOR.WRITE ? '' : 'none',
              }}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <span>Please Wait...</span>
                  <Spinner
                    color="dark"
                    style={{ height: '25px', width: '25px' }}
                  />
                </>
              ) : (
                'Delete Offer'
              )}
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openResendConfirmation}
          onClose={handleResendConfirmationClose}
          style={{ margin: '20px', textAlign: 'center' }}
        >
          <DialogTitle>Confirm Resend Email</DialogTitle>
          <DialogContent style={{ padding: '20px' }}>
            <DialogContentText>
              Are you sure you want to resend the email for this offer on{' '}
              <strong>{confirmationEmail}</strong>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResendConfirmationClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleResendConfirmationConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={apiResponse.toastOpen}
          autoHideDuration={6000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div>
            <Alert
              onClose={handleToastClose}
              severity={apiResponse.success ? 'success' : 'error'}
            >
              {apiResponse.message}
            </Alert>
          </div>
        </Snackbar>

        {!isLoading && (!dataSource || !dataSource.length) && (
          <div>No Offers found</div>
        )}
      </Grid>
    </Grid>
  );
}

export default SubscriptionErrors;
