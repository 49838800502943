import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Quotes List</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

function OfferQuoteList({ offerId, appliedFilter, viewHeight = '30vh' }) {
  const columns = [];
  columns.push({
    headerName: 'Date',
    renderCell: (c) =>
      moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
    field: 'createdAt',
    headerClassName: 'custom-dark-theme--header',
    width: 160,
  });
  if (isUK) {
    columns.push({
      headerName: 'Quote Uuid',
      render: (e) => e.quoteUuid,
      field: 'quoteUuid',
      headerClassName: 'custom-dark-theme--header',
      width: 320,
    });
    columns.push({
      headerName: 'Quote Number',
      render: (e) => e.quoteNumber,
      field: 'quoteNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    });
  }
  columns.push({
    headerName: 'Quote Id',
    render: (e) => e.quoteId,
    field: 'quoteId',
    headerClassName: 'custom-dark-theme--header',
    width: isUK ? 180 : 300,
  });
  columns.push({
    headerName: 'Payment',
    render: (e) => e.paymentOption,
    field: 'paymentOption',
    headerClassName: 'custom-dark-theme--header',
    width: 70,
  });
  columns.push({
    headerName: 'Mileage',
    render: (e) => e.mileage,
    field: 'mileage',
    headerClassName: 'custom-dark-theme--header',
    width: 75,
  });
  columns.push({
    headerName: 'First Collection',
    renderCell: (c) =>
      moment(c.value).format(process.env.REACT_APP_DATE_FORMAT),
    field: 'firstDateOfCollection',
    headerClassName: 'custom-dark-theme--header',
    width: 110,
  });
  columns.push({
    headerName: 'First Amount',
    render: (e) => e.firstCollectionAmount,
    field: 'firstCollectionAmount',
    headerClassName: 'custom-dark-theme--header',
    width: 100,
  });
  columns.push({
    headerName: 'Frequency',
    render: (e) => e.collectionFrequency,
    field: 'collectionFrequency',
    headerClassName: 'custom-dark-theme--header',
    width: 100,
  });
  if (!isUK) {
    columns.push({
      headerName: 'Sales Tax %',
      render: (e) => e.salesTaxPercentage,
      field: 'salesTaxPercentage',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    });
    columns.push({
      headerName: 'Sales Tax',
      render: (e) => e.salesTaxAmount,
      field: 'salesTaxAmount',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    });
  }
  if (isUK) {
    columns.push({
      headerName: 'Monthly',
      render: (e) => e.totalMonthlyAmount,
      field: 'totalMonthlyAmount',
      headerClassName: 'custom-dark-theme--header',
      width: 80,
    });
    columns.push({
      headerName: 'Months',
      render: (e) => e.numberOfMonths,
      field: 'numberOfMonths',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
    });
    columns.push({
      headerName: 'Total',
      render: (e) => e.totalAmount,
      field: 'totalAmount',
      headerClassName: 'custom-dark-theme--header',
      width: 80,
    });
  }
  columns.push({
    headerName: 'Contract Date',
    renderCell: (c) =>
      moment(c.value).format(process.env.REACT_APP_DATE_FORMAT),
    field: 'contractStartDate',
    headerClassName: 'custom-dark-theme--header',
    width: 120,
  });
  columns.push({
    headerName: 'Product Name',
    render: (e) => e.productName,
    field: 'productName',
    headerClassName: 'custom-dark-theme--header',
    width: isUK ? 280 : 200,
  });
  columns.push({
    headerName: 'Coverage',
    render: (e) => e.productCoverage,
    field: 'productCoverage',
    headerClassName: 'custom-dark-theme--header',
    width: isUK ? 140 : 120,
  });
  // columns.push({
  //   headerName: 'Subsequement Amount',
  //   render: (e) => e.subsequentInstallment,
  //   field: 'subsequentInstallment',
  //   headerClassName: 'custom-dark-theme--header',
  //   width: 120,
  // });
  if (isUK) {
    columns.push({
      headerName: 'Merchant Id',
      render: (e) => e.merchantId,
      field: 'merchantId',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    });
  }
  // columns.push({
  //   headerName: 'Base Deductible',
  //   render: (e) => e.deductibleBase,
  //   field: 'deductibleBase',
  //   headerClassName: 'custom-dark-theme--header',
  //   width: 120,
  // });
  // columns.push({
  //   headerName: 'Term Months',
  //   render: (e) => e.termMonths,
  //   field: 'termMonths',
  //   headerClassName: 'custom-dark-theme--header',
  //   width: 70,
  // });
  // columns.push({
  //   headerName: 'Term Miles',
  //   render: (e) => e.termMiles,
  //   field: 'termMiles',
  //   headerClassName: 'custom-dark-theme--header',
  //   width: 120,
  // });
  columns.push({
    headerName: 'Product Id',
    render: (e) => e.productId,
    field: 'productId',
    headerClassName: 'custom-dark-theme--header',
    width: 190,
  });
  if (!isUK) {
    columns.push({
      headerName: 'Account Number',
      render: (e) => e.accountNumber,
      field: 'accountNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    });
  }

  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index };
    });
    setDataSource(_updatedDataSource);
  };

  const fetchReport = async () => {
    const params = {
      page,
      limit: pageSize,
      filter: appliedFilter
        ? JSON.stringify(appliedFilter)
        : offerId
        ? JSON.stringify({
            offer_id: {
              eq: offerId,
            },
          })
        : {},
    };
    setIsLoading(true);
    const result = await subscriptionsApi.getOfferQuotes(dispatch, params);
    return result;
  };

  useEffect(() => {
    const loadData = async () => {
      setDataSource([]);
      const reports = await fetchReport();
      if (reports && reports.data.data) {
        updateDataSource(reports.data.data);
        setRowCount(reports.data.totalItems);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, offerId, appliedFilter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationMode="server"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default OfferQuoteList;
